import $ from 'cash-dom';

const showEvents = function(events) {
	if(events.length) {
		let eventsTable = '';

		for(let i = 0; i < events.length; i++) {
			eventsTable += '<tr>';
			eventsTable += '<td class="event-right">' + events[i].event_date + '</td>';
			eventsTable += '<td class="event-left">' + events[i].name + '</td>';
			eventsTable += '<td class="event-left">' + events[i].location + '</td>';
			eventsTable += '</tr>';
		}

		$('#events-table-body').html(eventsTable);
	} else {
		$('#no-events').text('Derzeit finden keine Termine statt.').show();
	}
};

const loadEvents = function() {
	const request = new XMLHttpRequest();
	request.open('GET', 'https://shop.weingut-winkler.de/api/events', true);

	request.onload = function() {
		if (request.status >= 200 && request.status < 400) {
			try {
				const data = JSON.parse(request.responseText);
				showEvents(data);
			} catch(e) {
				console.log('event load json error');
			}
			
		} else {
			console.log('event load server error');
		}
	};

	request.onerror = function() {
		console.log('event load xhr error');
	};

	request.send();
};

loadEvents();
