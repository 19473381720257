import $ from 'cash-dom';
import SVGInject from '@iconfu/svg-inject';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

import './zimmeranfrage';
import './veranstaltungen';

let logo = null;

SVGInject(document.querySelectorAll('img.inject'), {
	onAllFinish: _ => logo = document.getElementById('logo')
});

const menuToggle = document.getElementById('show-menu');
const menu = document.getElementById('menu-wrapper');
const mainMenuLinks = document.querySelectorAll('#main-menu a');
let menuTitle = document.getElementById('menu-title');

const pricingLinks = document.querySelectorAll('.pricing-link');

const burger = document.getElementById('burger');
const classes = {
	burger: 'burger--light',
	logo: 'logo--darker'
};

function toggleMenu() {
	if(menuTitle) {
		menuTitle.parentNode.removeChild(menuTitle);
		menuTitle = null;
	}

	if(menuToggle.checked) {
		disableBodyScroll(menu);

		if (logo) {
			$(logo).addClass(classes.logo);
		}
	} else {
		enableBodyScroll(menu);

		if (logo) {
			const elements = document.elementsFromPoint(0, burger.offsetTop);

			for (let i = 0; i < elements.length; i++) {
				if (elements[i].getAttribute('data-scroll-change')) return;
			}

			$(logo).removeClass(classes.logo);
		}
	}
}

menuToggle.addEventListener('change', toggleMenu);

window.addEventListener('scroll', function(e) {
	const section = document.elementFromPoint(0, burger.offsetTop);
	const element = section.getAttribute('data-scroll-change');

	if (section && element === 'burger') {
		$(burger).addClass(classes.burger);

		if (logo) {
			$(logo).addClass(classes.logo);
		}
	} else {
		$(burger).removeClass(classes.burger);

		if (logo) {
			$(logo).removeClass(classes.logo);
		}
	}
});

for (let i = 0; i < mainMenuLinks.length; i++) {
	mainMenuLinks[i].addEventListener('click', function(e) {
		if(this.getAttribute("href").substr(0, 2) !== '/#' || window.location.pathname !== '/') return;

		if (window.scroll) {
			e.preventDefault();
			const href = this.getAttribute("href");
			const offsetTop = document.querySelector(href.substr(1)).offsetTop;

			scroll({
				top: offsetTop,
				behavior: "smooth"
			});
		}

		menuToggle.checked = false;
		toggleMenu();

		this.className = 'active';
	});

	mainMenuLinks[i].addEventListener('mouseover', function(e) {
		for (let j = 0; j < mainMenuLinks.length; j++) {
			if(mainMenuLinks[j].className) mainMenuLinks[j].className ='';
		}
	});
}

for (let j = 0; j < pricingLinks.length; j++) {
	pricingLinks[j].addEventListener('click', function(e) {
		e.preventDefault();
		const target = document.querySelector(this.hash);
		if(target) target.style.opacity = 1;
	});
}
