const requestForm = document.getElementById('room-request-form');

const errorMsg = function(text) {
	const div = document.createElement('div');
	div.className = 'error';
	div.innerHTML = text;

	return div;
};

const sendForm = function(e) {
	e.preventDefault();

	const data = new FormData(requestForm);
	const xhr = new XMLHttpRequest();

	xhr.addEventListener('load', showResponse);
	xhr.responseType = 'json';
	xhr.open('POST', requestForm.action);
	xhr.send(data);
};

const showResponse = function(e) {
	const xhr = e.target;
	const errors = requestForm.querySelectorAll('.error, .success');

	Array.prototype.forEach.call(errors, function(node) {
		node.parentNode.removeChild(node);
	});

	if (xhr.status === 200) {
		requestForm.innerHTML = '<div class="succes">Vielen Dank für Ihre Anfrage!<br><br>Wir werden diese umgehend bearbeiten und uns bei Ihnen melden.</div>';

	} else if (xhr.status === 400) {
		for(const field in xhr.response.error) {
			const element = requestForm.elements[field];

			for(let i = 0; i < xhr.response.error[field].length; i++) {
				element.parentNode.appendChild(errorMsg(xhr.response.error[field][i]));
			}
		}

	} else {
		requestForm.appendChild(errorMsg('Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmals. Vielen Dank für Ihr Verständnis.'));
	}
};

if (requestForm) {
	requestForm.addEventListener('submit', sendForm);
}
